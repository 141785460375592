import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { Heading, HeadingSmall } from "./typography"
import Gut from "./gut"

const AgencyDetails = ({ agency }) => (
  <Gut gutter={.5}>
    <Heading>{agency.name}</Heading>
    <HeadingSmall>{agency.address}</HeadingSmall>
    {agency.phone && (
      <Detail>
        Telefono: <span>{agency.phone}</span>
      </Detail>
    )}
    {agency.email && (
      <Detail>
        Email:{" "}
        <a href={`mailto:${agency.email}`}>
          <span>{agency.email}</span>
        </a>
      </Detail>
    )}
    {agency.website && (
      <Detail>
        Sito web:{" "}
        <a href={agency.website}>
          <span>{agency.website}</span>
        </a>
      </Detail>
    )}
    {agency.fax && (
      <Detail>
        Fax: <span>{agency.fax}</span>
      </Detail>
    )}
  </Gut>
)
const Wrapper = styled.div``

const Detail = styled.div`
  text-transform: uppercase;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  color: ${DesignTokens.colors.secondary[300]};
  span {
    color: ${DesignTokens.colors.secondary[500]};
  }
`

export default AgencyDetails
