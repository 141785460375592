import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { Body, Display } from "../components/typography"
import Select from "react-select"
import PageTitle from "../components/pageTitle"
import AgencyDetails from "../components/agencyDetails"
import Gut from "../components/gut"

const NewsListPage = ({ data: { agencies, locations }, pageContext }) => {
  // console.log(agencies, pageContext)
  const [filteredAgencies, setFilteredAgencies] = useState([])

  function filterAgencies(originalId) {
    if (pageContext.distribution) {
      setFilteredAgencies(
        agencies.nodes.filter(
          agency =>
            agency.distribution === true &&
            sameLocation(agency.area, originalId)
        )
      )
    }
    if (pageContext.promotion) {
      setFilteredAgencies(
        agencies.nodes.filter(
          agency =>
            agency.promotion === true && sameLocation(agency.area, originalId)
        )
      )
    }
  }

  function sameLocation(areas, originalId) {
    return areas.some(area => area.originalId === originalId)
  }
  //   TODO - agency.area is an array
  const locationsSelect = locations.nodes.map(location => ({
    value: location.originalId,
    label: location.name,
  }))
  // console.log(locations.nodes)
  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Wrapper>
        <Container>
          <Row justify="center">
            <Col lg={9}>
              <PageTitle>
                <Display>{pageContext.title}</Display>
                {pageContext.promotion && (
                  <>
                    <Body>
                      Sei un <strong>docente</strong> e sei interessato alla
                      nostra produzione editoriale?
                    </Body>
                    <Body>
                      Siamo a tua disposizione per ogni informazione e per la
                      fornitura di saggi a scopo adozionale, attraverso la
                      nostra rete di concessionarie, che si estende su tutto il
                      territorio nazionale.
                    </Body>
                  </>
                )}
                {pageContext.distribution && (
                  <>
                    <Body>
                      Sei un <strong>libraio</strong> e vuoi effettuare un
                      ordine dei nostri libri?
                    </Body>
                    <Body>
                      Siamo a tua disposizione per ogni informazione e per la
                      fornitura dei prodotti richiesti, attraverso la nostra
                      rete di concessionarie, che si estende su tutto il
                      territorio nazionale.
                    </Body>
                  </>
                )}
              </PageTitle>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={8}>
              {pageContext.promotion && (
                <Body>
                  Seleziona la tua Provincia e trova i recapiti dell’agente più
                  vicino a te.
                </Body>
              )}
              {pageContext.distribution && (
                <Body>
                  Seleziona la tua Provincia e trova i recapiti del distributore
                  più vicino a te.
                </Body>
              )}
              <Gut gutter={2}>
                <Select
                  placeholder="Seleziona la provincia"
                  options={locationsSelect}
                  onChange={e => filterAgencies(e.value)}
                />
                <Row>
                  {filteredAgencies.map(agency => (
                    <Col key={agency.originalId}>
                      <AgencyDetails agency={agency} />
                    </Col>
                  ))}
                </Row>
              </Gut>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-bottom: 20rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default NewsListPage

export const query = graphql`
  query AllAgenciesQuery {
    agencies: allDatoCmsAgency(sort: { fields: name, order: ASC }) {
      nodes {
        distribution
        promotion
        name
        originalId
        address
        email
        fax
        phone
        website
        area {
          originalId
          name
        }
      }
    }
    locations: allDatoCmsLocation(
      filter: { root: { eq: false } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        originalId
      }
    }
  }
`
